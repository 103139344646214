<template lang="">
  <div class="card card--mt card--pb-0">
    <div class="main-heading mb-4">
      State Association Goal Planner - {{ this.stateDashboardDetails.StateName }}
      <span class="badge badge--success" v-if="stateDashboardDetails.IsStateLocked"> - STATE IS LOCKED</span>
    </div>
    <div class="widget-bg widget-bg--dir-column mb-4">
      <h4>Plan Fiscal Year - {{ this.stateDashboardDetails.PlanFiscalYear }}</h4>
      <div class="h-row">
        <div class="h-row__col">
          <div class="form-group">
            <label>Region</label>
            <span
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="View all or filtered camp lists."
              class="edit-circle"
            >
              <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
            </span>
            <select v-model="selectedRegion" @change="changeFilter" class="form-control w-100">
              <option value="All">All</option>
              <option v-for="region in this.stateDashboardDetails.Regions" v-bind:value="region" :key="region">{{
                region
              }}</option>
            </select>
          </div>
        </div>
        <div class="h-row__col">
          <div class="form-group">
            <label>Area</label>
            <span
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="View all or filtered camp lists."
              class="edit-circle"
            >
              <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
            </span>
            <select v-model="selectedArea" @change="changeFilter" class="form-control w-100">
              <option value="All">All</option>
              <option v-for="area in this.stateDashboardDetails.Areas" v-bind:value="area" :key="area">{{
                area
              }}</option>
            </select>
          </div>
        </div>
        <div class="h-row__col">
          <div class="form-group">
            <label>Category</label>
            <select v-model="selectedCategory" @change="handleCategoryChange" class="form-control w-100">
              <optgroup label="Receipts">
                <option value="RC" selected="selected">All Receipts</option>
                <option value="CM">Church Ministry</option>
                <option value="GC">GideonCard</option>
                <option value="FF">International Faith Fund</option>
                <option value="BF">Barnabas Fund</option>
                <option value="AS">Auxilliary Scripture</option>
                <option value="DC">Camp Designated</option>
              </optgroup>
              <optgroup label="ChurchPresentation">
                <option value="CP">Church Presentations</option>
              </optgroup>
              <optgroup label="Placements">
                <option value="PL">Gideon Placements</option>
                <option value="PA">Auxiliary Placements</option>
              </optgroup>
              <optgroup label="PWTs">
                <option value="GP">Gideon PWTs</option>
                <option value="AP">Auxiliary PWTs</option>
              </optgroup>
              <optgroup label="Membership">
                <option value="MG">Gideon Membership</option>
                <option value="MA">Auxiliary Membership</option>
              </optgroup>
            </select>
          </div>
        </div>
      </div>
      <div class="widget-bg__footer">
        <div class="footer__lft-col">
          Applied Filter:
          <ul>
            <li><strong>Region:</strong> {{ filteredRegion }}</li>
            <li><strong>Area:</strong> {{ filteredArea }}</li>
            <li><strong>Category:</strong> {{ filteredCategoryName }}</li>
          </ul>
        </div>
        <div class="footer__rgt-col">
          <button
            v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
            title="Users must select filters (Region,Area,Category) and click Apply Filter to view results in the grid."
            type="button"
            class="btn  d-flex flex-row justify-content-end"
            :class="isFilterChanged ? 'btn-primary ' : 'btn-cancel'"
            @click="applyFilterHandler"
          >
            Apply filter
          </button>
          <button class="btn btn-cancel" @click="resetFilter">
            Reset
          </button>
        </div>
      </div>
    </div>
    <h4>Goal Summary</h4>
    <b-row>
      <b-col md="7" sm="8" v-if="showRunStateTargetSection">
        <div class="d-flex input-grp-wrap align-items-start">
          <label>
            <span
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="Insert the overall State Goal and select suggested goal will be created for all camps that have not locked."
              class="edit-circle"
            >
              <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
            </span>
            State Target Goal
          </label>
          <div>
            <b-input-group :prepend="isDollarValue() ? '$' : ''">
              <GoalBaseInput
                class="form-control text-content-right"
                v-model="stateTargetGoal"
                :maximumDigitsAllowed="stateTargetGoalMaximumBound"
              />
            </b-input-group>
            <div v-if="$v.stateTargetGoal.$invalid" class="error-msg">
              A number is required.
            </div>
          </div>
          <button type="button" @click="runStateTargetGoalHandler" class="btn-ternary">Run target</button>
          <span
            v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
            title="Spreads the amount inserted in the State Target Goal."
            class="edit-circle"
          >
            <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
          </span>
        </div>
      </b-col>
      <b-col md="5" sm="4">
        <div class="pr-widget">
          <label>
            <span
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="Displays a running total of all goals."
              class="edit-circle"
            >
              <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
            </span>
            Current Camp Goal Total
          </label>
          <span> {{ currentCampTotal }}</span>
        </div>
        <div class="pr-widget">
          <label>
            <span
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="Displays a running total of only locked camps."
              class="edit-circle"
            >
              <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
            </span>
            Camp Locked Goal Total
          </label>
          <span> {{ lockedCampTotal }}</span>
        </div>
      </b-col>
    </b-row>
    <div
      v-if="!this.stateDashboardDetails.IsStateLocked"
      class="widget-bg justify-content-between mt-3 mb-2 widget-bg--lg-content"
    >
      <div class="d-flex align-items-center">
        <span
          v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
          title="Locking finalizes and submits all goal planning."
          class="edit-circle"
        >
          <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
        </span>
        <button
          @click="lockStateHandler"
          :disabled="hasStatePlanningCompletionDatePassed"
          type="button"
          class="btn btn--yellow btn--style mr-3"
        >
          Lock All Goals
        </button>
        <a href="javascript:void(0)" class="btn btn-primary mr-3" @click="exportCampStatusReport">Camp Status Report</a>
        <a href="javascript:void(0)" class="btn btn-primary mr-3" @click="exportCategoryCsv">
          Export {{ csvName }} CSV
        </a>
      </div>
      <b-input-group size="sm" prepend="Upload CSV">
        <b-form-file
          v-model="stateUploadCSVFile"
          :state="Boolean(stateUploadCSVFile)"
          accept=".csv"
          placeholder="Choose a file"
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <b-input-group-append is-text>
          <button type="button" @click="stateUploadCSVHandler">
            <img src="@/assets/svgs/goal-planner/upload_ic.svg" alt="upload" />
          </button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div v-else class="widget-bg justify-content-end mt-2 mb-2">
      <a href="javascript:void(0)" class="btn btn-primary" @click="exportCampStatusReport">Camp Status Report</a>
    </div>
    <state-planner-table
      ref="tableResetRef"
      :camps="stateCamps"
      :selectedCategory="filteredCategoryStateTablePropsData"
      @state-dashboard-data-changed="refetchDashboardData"
      @triggered-quick-goal-planning-modal="quickGoalPlanningHandler"
    ></state-planner-table>
    <quick-goal-planning
      v-if="modalShow"
      :isModalShow="modalShow"
      @state-dashboard-data-changed="refetchDashboardData"
      @close-modal="openOrCloseModal(false)"
      :camp="selectedCamp"
    ></quick-goal-planning>
  </div>
</template>
<script>
/*eslint-disable*/
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import StatePlannerTable from '@/components/state-goal-planner/StatePlannerTable.vue'
import QuickGoalPlanning from '@/components/state-goal-planner/QuickGoalPlanning.vue'
import { FormatNumber } from '@/services/utils/FormatNumber.js'
import { GoalShortCodes } from '@/constants/GoalShortCodes'
import { CampGoalsAllowedMaximumDigits } from '@/constants/CampGoalsMaximumBoundConstants.js'
import GoalBaseInput from '@/components/camp-goal-planner/GoalBaseInput.vue'
import { required, numeric } from 'vuelidate/lib/validators'
import CampGoalService from '@/services/goals/CampGoal.js'

export default {
  data() {
    return {
      stateTargetGoal: 0,
      stateTargetGoalMaximumBound: CampGoalsAllowedMaximumDigits.StateTargetLimit,
      isFilterChanged: false,
      selectedCamp: null,
      modalShow: false,
      selectedRegion: 'All',
      selectedArea: 'All',
      selectedCategory: GoalShortCodes.Receipts,
      selectedCategoryName: 'All Receipts',
      filteredRegion: 'All',
      filteredArea: 'All',
      filteredCategory: GoalShortCodes.Receipts,
      filteredCategoryName: 'All Receipts',
      currentCampTotal: 0,
      lockedCampTotal: 0,
      stateUploadCSVFile: null,
      csvName: 'Receipts',
      csvTypes: [
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.Receipts
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.ChurchMinistry
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.GideonCard
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.FaithFund
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.AuxiliaryScripture
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.CampDesignated
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.BarnabasFund
        },
        {
          key: 'churchpresentation',
          label: 'Church Presentation',
          shortCode: GoalShortCodes.ChurchPresentation
        },
        {
          key: 'gideonsplacements',
          label: 'Gideons Placements',
          shortCode: GoalShortCodes.GideonPlacements
        },
        {
          key: 'auxiliaryplacements',
          label: 'Auxillary Placements',
          shortCode: GoalShortCodes.AuxiliaryPlacements
        },
        {
          key: 'gideonpwts',
          label: 'Gideon PWTs',
          shortCode: GoalShortCodes.GideonPWTs
        },
        {
          key: 'auxiliarypwts',
          label: 'Auxiliary PWTs',
          shortCode: GoalShortCodes.AuxiliaryPWTs
        },
        {
          key: 'newgideonmembers',
          label: 'Gideon Membership',
          shortCode: GoalShortCodes.GideonMembership
        },
        {
          key: 'newauxiliarymembers',
          label: 'Auxiliary Membership',
          shortCode: GoalShortCodes.AuxiliaryMembership
        }
      ]
    }
  },
  provide() {
    return {
      isDollarValue: this.isDollarValue,
      isSelectedStateLocked: () => this.stateDashboardDetails.IsStateLocked
    }
  },
  validations: {
    stateTargetGoal: { numeric, required }
  },
  computed: {
    ...mapGetters({
      userStateKey: 'user/userStateKey',
      userId: 'user/userId',
      stateDashboardDetails: 'stateGoalPlanner/stateDashboardDetails',
      stateCamps: 'stateGoalPlanner/stateCampsDetails'
    }),
    showRunStateTargetSection() {
      const membershipShortCodes = [GoalShortCodes.GideonMembership, GoalShortCodes.AuxiliaryMembership]
      return !membershipShortCodes.includes(this.filteredCategory) && !this.stateDashboardDetails.IsStateLocked
    },
    filteredCategoryStateTablePropsData() {
      return {
        categoryName: this.filteredCategoryName,
        categoryShortCode: this.filteredCategory
      }
    },
    hasStatePlanningCompletionDatePassed() {
      const diff = new Date(this.stateDashboardDetails.StatePlanningCompletionDate) - new Date()
      return diff < 0 ? true : false
    }
  },
  components: {
    StatePlannerTable,
    QuickGoalPlanning,
    GoalBaseInput
  },
  methods: {
    ...mapActions({
      getStateDetails: 'stateGoalPlanner/getStateDashboardDetails',
      getStateCampsDetails: 'stateGoalPlanner/getStateCampsDetails',
      exportCSV: 'stateGoalPlanner/exportCSV',
      downloadCampStatusReport: 'stateGoalPlanner/downloadCampStatusReport',
      getStateCampsDetails: 'stateGoalPlanner/getStateCampsDetails',
      lockState: 'stateGoalPlanner/lockState',
      runStateTargetGoal: 'stateGoalPlanner/runStateTargetGoal',
      uploadStateCSV: 'stateGoalPlanner/uploadStateCSV'
    }),
    async stateUploadCSVHandler() {
      if ((this.stateUploadCSVFile && this.stateUploadCSVFile.type) === 'text/csv') {
        const response = await this.uploadStateCSV({
          stateOrgKey: this.userStateKey,
          indKey: this.userId,
          file: this.stateUploadCSVFile
        })
        this.stateUploadCSVFile = null
        if (response === 'Success') {
          await this.refetchDashboardData()
        }
      } else {
        Vue.swal({
          title: 'Only file with .csv extension is allowed',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          padding: '12px',
          timer: 5000,
          grow: 'row',
          icon: 'error',
          timerProgressBar: true,
          width: '400px'
        })
        this.stateUploadCSVFile = null
      }
    },
    async runStateTargetGoalHandler() {
      if (this.$v.stateTargetGoal.$invalid) {
        return
      }
      const payload = {
        StateOrgKey: this.userStateKey,
        ShortCode: this.filteredCategory,
        TargetGoal: this.stateTargetGoal
      }

      const response = await this.runStateTargetGoal({ targetGoalData: payload })
      if (response === 'Success') {
        await this.getCampsDetails()
        this.stateTargetGoal = 0
      }
    },
    async applyFilterHandler() {
      await this.getCampsDetails()
      this.resetApplyFilterButtonCSS()
    },
    async refetchDashboardData() {
      await this.getCampsDetails()
    },
    async loadState() {
      await this.getStateDetails({ stateOrgKey: this.userStateKey })
      await this.getCampsDetails()
    },
    async getCampsDetails() {
      await this.getStateCampsDetails({
        stateOrgKey: this.userStateKey,
        region: this.selectedRegion,
        area: this.selectedArea,
        category: this.selectedCategory
      })
      this.filteredRegion = this.selectedRegion
      this.filteredArea = this.selectedArea
      this.filteredCategory = this.selectedCategory
      this.filteredCategoryName = this.selectedCategoryName
      this.calculateCampsTotal()
      this.getCsvName()
    },
    async resetFilter() {
      this.resetFilterValues()
      await this.getCampsDetails()
      this.resetApplyFilterButtonCSS()
      // resetting the planner table state
      this.$refs.tableResetRef.resetState()
    },
    async exportCampStatusReport() {
      await this.downloadCampStatusReport({
        stateOrgKey: this.userStateKey,
        fiscalYear: this.stateDashboardDetails.FiscalYear
      })
    },
    async exportCategoryCsv() {
      let csvType = this.csvTypes.find(x => x.shortCode == this.filteredCategory).key
      await this.exportCSV({
        stateOrgKey: this.userStateKey,
        csvType: csvType,
        fiscalYear: this.stateDashboardDetails.FiscalYear
      })
    },
    async lockStateHandler() {
      this.$swal({
        icon: 'warning',
        title: '<span class="confirmation-modal-header" >State Association Goal Planner Lock Message</span>',
        html: `<p class='confirm-modal-text'>All camp goals in the goal planner will now be locked and goal setting will be completed. Once done, the State Goal Planner cannot be unlocked. Please verify the categories for Receipts, Gideon Placements, Auxiliary Placements, and Church Presentations have all been planned prior to selecting OK.</p>
          <p> Select OK to finish goal planning, or Cancel to continue.</p>
          `,
        showCancelButton: true,
        width: '700px'
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await this.lockState({ stateOrgKey: this.userStateKey })
          if (response === 'Success') {
            this.resetFilterValues()
            await this.loadState()
          }
        }
      })
    },
    resetFilterValues() {
      this.selectedRegion = 'All'
      this.selectedArea = 'All'
      this.selectedCategory = GoalShortCodes.Receipts
      this.selectedCategoryName = 'All Receipts'
      this.stateUploadCSVFile = null
    },
    handleCategoryChange(e) {
      this.changeFilter()
      if (e.target.options.selectedIndex > -1) {
        this.selectedCategoryName = e.target.options[e.target.options.selectedIndex].text
      }
    },
    getCsvName() {
      this.csvName = this.csvTypes.find(x => x.shortCode == this.filteredCategory).label
    },
    calculateCampsTotal() {
      let sumOfLockedCamps = 0
      let sumOfNotLockedCamps = 0
      this.stateCamps.forEach(obj => {
        if (obj.IsCampLocked === true) {
          sumOfLockedCamps += obj.Goal
        } else {
          sumOfNotLockedCamps += obj.GoalCalculated
        }
      })
      this.currentCampTotal = this.isDollarValue()
        ? `$${FormatNumber(sumOfLockedCamps + sumOfNotLockedCamps)}`
        : FormatNumber(sumOfLockedCamps + sumOfNotLockedCamps)
      this.lockedCampTotal = this.isDollarValue()
        ? `$${FormatNumber(sumOfLockedCamps)}`
        : FormatNumber(sumOfLockedCamps)
    },
    quickGoalPlanningHandler(camp) {
      this.selectedCamp = camp
      this.openOrCloseModal(true)
    },
    openOrCloseModal(open) {
      this.modalShow = open
    },
    changeFilter() {
      this.isFilterChanged = true
    },
    resetApplyFilterButtonCSS() {
      this.isFilterChanged = false
    },
    isDollarValue(shortCode) {
      let selectedCategory = shortCode == undefined || shortCode == '' ? this.filteredCategory : shortCode
      return CampGoalService.isGoalDollar(selectedCategory)
    }
  },
  watch: {
    userStateKey: {
      async handler() {
        this.resetFilterValues()
        // resetting run state target value
        this.stateTargetGoal = 0
        await this.loadState()
      },
      deep: true
    }
  },
  async created() {
    await this.loadState()
  }
}
</script>
<style lang="scss">
.text-content-right {
  text-align: right;
}
.confirmation-modal-header {
  font-size: 0.98em;
  display: inline-block;
}

.confirm-modal-text {
  margin-bottom: 10px;
  font-size: 0.98em;
}

.confirm-modal-text + p {
  font-size: 0.98em;
}

.main-heading {
  color: #000;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .badge {
    font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: none;
  }
}

.badge {
  position: relative;
  padding: 0.35rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: inline-block;
  margin-left: 10px;
  font-size: 13px;
  font-weight: normal;
}

.badge--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.btn {
  &.btn--style {
    text-transform: capitalize;
    font-weight: normal;
    margin-left: 10px;
    padding: 10px 25px;
  }

  &.btn--yellow {
    background: #fbc301;
    color: #fff;

    &:hover {
      background: #003946;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.input-grp-wrap {
  align-items: center;

  label {
    font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }
  .error-msg {
    margin: 0 15px;
    display: block;
    color: #df1515;
    font-weight: 600;
    font-size: 13px;
    line-height: 1;
    padding-right: 12px;
    margin-top: 5px;
  }

  .input-group {
    margin: 0 15px;
    width: auto;
    border-radius: 0;

    .input-group-text {
      background: #ececec;
      color: #003946;
      border-radius: 0;
    }
  }
}

.btn-ternary {
  background: #036f88;
  color: #fff;
  font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px;
  border: none;
  padding: 6px 20px;
  min-height: 38px;
  border-radius: 0.25rem;
  margin-right: 10px;
}

.pr-widget {
  display: flex;
  justify-content: space-between;

  span {
    text-align: right;

    svg {
      margin-right: 5px;
    }
  }
}

.planner-header {
  margin: 30px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin-bottom: 0;

    span {
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: #747474;
      text-transform: capitalize;
      display: inline-flex;
      margin-left: 15px;
    }
  }

  .planner-header__lft {
    display: flex;

    a {
      &:first-child {
        margin-right: 13px;
      }
    }

    .btn {
      padding: 11px 13px;
      text-transform: capitalize;

      img {
        margin-right: 5px;
      }
    }
  }
}

.widget-bg {
  background: #f8f8f8;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  display: flex;
  padding: 15px 16px;
  width: 100%;

  &.widget-bg--dir-column {
    flex-direction: column;
  }

  &.widget-bg--lg-content {
    .btn {
      padding: 11px 17px;
    }

    .input-group {
      max-width: 335px;
    }
  }

  .input-group > .custom-file {
    height: 100%;
  }

  .btn-primary {
    font-weight: normal;
    text-transform: capitalize;
  }

  .custom-file {
    .custom-file-input {
      height: auto;
    }

    .custom-file-label {
      height: 100%;
      margin: 0;
      padding: 10px;
      border-color: #003946;
      border-radius: 0;

      &:after {
        display: none;
      }
    }
  }

  .widget-bg__footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ebebeb;
    align-items: flex-start;
    padding-top: 10px;

    .footer__lft-col {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      color: #000;

      ul {
        display: flex;
        margin-top: 5px;

        li {
          list-style: none;
          display: inline-flex;
          background: #e3e3e3;
          border: 1px solid #cfcfcf;
          border-radius: 3px;
          padding: 3px 10px;
          margin-left: 10px;
          color: #434343;
          font-size: 14px;
          font-weight: normal;

          strong {
            margin-right: 4px;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .footer__rgt-col {
      display: flex;
    }

    .btn-primary {
      text-transform: capitalize;
      font-weight: normal;
    }

    .btn-cancel {
      border: 2px solid #003946;
      background: #fff;
      margin-left: 15px;
      font-weight: normal;
      text-transform: capitalize;

      &:hover {
        border-color: #003946;
        background: #003946;
        color: #fff;
      }
    }
  }

  .h-row {
    display: flex;
    width: 100%;

    .h-row__col {
      padding: 0 15px;
      width: 33.3336%;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      label {
        width: auto;
        min-width: auto;
        font-weight: normal;
        font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: #000;
        margin-bottom: 3px;
        margin-right: 0;
        padding-right: 5px;
      }

      .form-control {
        padding: 0.375rem 0.75rem;
        background: #fff;
      }
    }
  }

  .input-group {
    position: relative;
    margin: 0;
    width: auto;
    min-width: 370px;

    .input-group-text {
      background: #ececec;
      color: #003946;
    }

    input[type='text'] {
      min-height: 42px;
    }

    .input-group-append {
      .input-group-text {
        background: #003946;

        button {
          background: transparent;
          border: none;
        }
      }
    }
  }
}

.g-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;

  .g-panel__rgt {
    display: flex;
    align-items: flex-start;

    .btn {
      padding: 11px 13px;
      font-size: 15px;
      line-height: 22px;
      text-transform: capitalize;

      &:first-child {
        margin-right: 12px;
      }

      img {
        margin-right: 3px;
      }
    }
  }
}
</style>
